// Source:

const countryCodesWithImage = [
  "Ale",
  "Alingsås",
  "Alvesta",
  "Aneby",
  "Arboga",
  "Arjeplog",
  "Arvidsjaur",
  "Arvika",
  "Askersund",
  "Avesta",
  "Bengtsfors",
  "Berg",
  "Bjurholm",
  "Bjuv",
  "Boden",
  "Bollebygd",
  "Bollnäs",
  "Borgholm",
  "Borlänge",
  "Borås",
  "Botkyrka",
  "Boxholm",
  "Bromölla",
  "Bräcke",
  "Burlöv",
  "Båstad",
  "Dals-Ed",
  "Danderyd",
  "Degerfors",
  "Dorotea",
  "Eda",
  "Ekerö",
  "Eksjö",
  "Emmaboda",
  "Enköping",
  "Eskilstuna",
  "Eslöv",
  "Essunga",
  "Fagersta",
  "Falkenberg",
  "Falköping",
  "Falun",
  "Filipstad",
  "Finspång",
  "Flen",
  "Forshaga",
  "Färgelanda",
  "Gagnef",
  "Gislaved",
  "Ljusdal",
  "Nordanstig",
  "Nordmaling",
  "Pajala",
  "Robertsfors",
  "Sollefteå",
  "Sorsele",
  "Strömsund",
  "Torsby",
  "Vansbro",
  "Vilhelmina",
  "Vindeln",
  "Ånge",
  "Årjäng",
  "Åsele",
  "Överkalix",
  "Övertorneå",
  "Gnesta",
  "Gnosjö",
  "Grums",
  "Grästorp",
  "Gullspång",
  "Gällivare",
  "Gävle",
  "Göteborg",
  "Götene",
  "Habo",
  "Hagfors",
  "Hallsberg",
  "Hallstahammar",
  "Halmstad",
  "Hammarö",
  "Haninge",
  "Haparanda",
  "Heby",
  "Hedemora",
  "Helsingborg",
  "Herrljunga",
  "Hjo",
  "Hofors",
  "Huddinge",
  "Hudiksvall",
  "Hultsfred",
  "Hylte",
  "Hällefors",
  "Härjedalen",
  "Härnösand",
  "Härryda",
  "Hässleholm",
  "Håbo",
  "Höganäs",
  "Högsby",
  "Hörby",
  "Höör",
  "Jokkmokk",
  "Järfälla",
  "Jönköping",
  "Kalix",
  "Kalmar",
  "Karlsborg",
  "Karlshamn",
  "Karlskoga",
  "Karlskrona",
  "Karlstad",
  "Katrineholm",
  "Kil",
  "Kinda",
  "Kiruna",
  "Klippan",
  "Knivsta",
  "Kramfors",
  "Kristianstad",
  "Kristinehamn",
  "Krokom",
  "Kumla",
  "Kungsbacka",
  "Kungsör",
  "Kungälv",
  "Kävlinge",
  "Köping",
  "Laholm",
  "Landskrona",
  "Laxå",
  "Lekeberg",
  "Leksand",
  "Lerum",
  "Lessebo",
  "Lidingö",
  "Lidköping",
  "Lilla Edet",
  "Lindesberg",
  "Linköping",
  "Ljungby",
  "Ljusnarsberg",
  "Lomma",
  "Ludvika",
  "Luleå",
  "Lund",
  "Lycksele",
  "Lysekil",
  "Malmö",
  "Malung-Sälen",
  "Malå",
  "Mariestad",
  "Markaryd",
  "Mark",
  "Mellerud",
  "Mjölby",
  "Mora",
  "Motala",
  "Mullsjö",
  "Munkedal",
  "Munkfors",
  "Mölndal",
  "Mönsterås",
  "Mörbylånga",
  "Nacka",
  "Nora",
  "Norberg",
  "Norrköping",
  "Norrtälje",
  "Norsjö",
  "Nybro",
  "Nykvarn",
  "Nyköping",
  "Nynäshamn",
  "Nässjö",
  "Ockelbo",
  "Olofström",
  "Orsa",
  "Orust",
  "Osby",
  "Oskarshamn",
  "Ovanåker",
  "Oxelösund",
  "Partille",
  "Perstorp",
  "Piteå",
  "Ragunda",
  "Gotland",
  "Ronneby",
  "Rättvik",
  "Sala",
  "Salem",
  "Sandviken",
  "Sigtuna",
  "Simrishamn",
  "Sjöbo",
  "Skara",
  "Skellefteå",
  "Skinnskatteberg",
  "Skurup",
  "Skövde",
  "Smedjebacken",
  "Sollentuna",
  "Solna",
  "Sotenäs",
  "Staffanstorp",
  "Stenungsund",
  "Stockholm",
  "Storfors",
  "Storuman",
  "Strängnäs",
  "Strömstad",
  "Sundbyberg",
  "Sundsvall",
  "Sunne",
  "Surahammar",
  "Svalöv",
  "Svedala",
  "Svenljunga",
  "Säffle",
  "Säter",
  "Sävsjö",
  "Söderhamn",
  "Söderköping",
  "Södertälje",
  "Sölvesborg",
  "Tanum",
  "Tibro",
  "Tidaholm",
  "Tierp",
  "Timrå",
  "Tingsryd",
  "Tjörn",
  "Tomelilla",
  "Torsås",
  "Tranemo",
  "Tranås",
  "Trelleborg",
  "Trollhättan",
  "Trosa",
  "Tyresö",
  "Täby",
  "Töreboda",
  "Uddevalla",
  "Ulricehamn",
  "Umeå",
  "Upplands-Bro",
  "Upplands Väsby",
  "Uppsala",
  "Uppvidinge",
  "Vadstena",
  "Vaggeryd",
  "Valdemarsvik",
  "Vallentuna",
  "Vara",
  "Varberg",
  "Vaxholm",
  "Vellinge",
  "Vetlanda",
  "Vimmerby",
  "Vingåker",
  "Vänersborg",
  "Vännäs",
  "Värmdö",
  "Värnamo",
  "Västervik",
  "Västerås",
  "Växjö",
  "Vårgårda",
  "Ydre",
  "Ystad",
  "Älmhult",
  "Älvdalen",
  "Älvkarleby",
  "Älvsbyn",
  "Ängelholm",
  "Åmål",
  "Åre",
  "Åstorp",
  "Åtvidaberg",
  "Öckerö",
  "Ödeshög",
  "Örebro",
  "Örkelljunga",
  "Örnsköldsvik",
  "Östersund",
  "Österåker",
  "Östhammar",
  "Östra Göinge",
];

export interface Country {
  code: string;
  latitude: number;
  longitude: number;
  name: string;
}

export const countries: Country[] = [
  {
    name: "Ale",
    code: "Ale",
    longitude: 12.086944444444,
    latitude: 57.925,
  },
  {
    name: "Alingsås",
    code: "Alingsås",
    longitude: 12.533333333333,
    latitude: 57.933333333333,
  },
  {
    name: "Alvesta",
    code: "Alvesta",
    longitude: 14.55,
    latitude: 56.9,
  },
  {
    name: "Aneby",
    code: "Aneby",
    longitude: 14.8,
    latitude: 57.833333333333,
  },
  {
    name: "Arboga",
    code: "Arboga",
    longitude: 15.833333333333,
    latitude: 59.4,
  },
  {
    name: "Arjeplog",
    code: "Arjeplog",
    longitude: 17.95,
    latitude: 66.033333333333,
  },
  {
    name: "Arvidsjaur",
    code: "Arvidsjaur",
    longitude: 19.116666666667,
    latitude: 65.583333333333,
  },
  {
    name: "Arvika",
    code: "Arvika",
    longitude: 12.583333333333,
    latitude: 59.65,
  },
  {
    name: "Askersund",
    code: "Askersund",
    longitude: 14.9,
    latitude: 58.883333333333,
  },
  {
    name: "Avesta",
    code: "Avesta",
    longitude: 16.168333333333,
    latitude: 60.145555555556,
  },
  {
    name: "Bengtsfors",
    code: "Bengtsfors",
    longitude: 12.216666666667,
    latitude: 59.033333333333,
  },
  {
    name: "Berg",
    code: "Berg",
    longitude: 14.45,
    latitude: 62.766666666667,
  },
  {
    name: "Bjurholm",
    code: "Bjurholm",
    longitude: 19.216666666667,
    latitude: 63.916666666667,
  },
  {
    name: "Bjuv",
    code: "Bjuv",
    longitude: 12.916666666667,
    latitude: 56.083333333333,
  },
  {
    name: "Boden",
    code: "Boden",
    longitude: 21.716666666667,
    latitude: 65.833333333333,
  },
  {
    name: "Bollebygd",
    code: "Bollebygd",
    longitude: 12.566666666667,
    latitude: 57.666666666667,
  },
  {
    name: "Bollnäs",
    code: "Bollnäs",
    longitude: 16.4,
    latitude: 61.35,
  },
  {
    name: "Borgholm",
    code: "Borgholm",
    longitude: 16.661666666667,
    latitude: 56.878055555556,
  },
  {
    name: "Borlänge",
    code: "Borlänge",
    longitude: 15.436388888889,
    latitude: 60.482777777778,
  },
  {
    name: "Borås",
    code: "Borås",
    longitude: 12.933333333333,
    latitude: 57.716666666667,
  },
  {
    name: "Botkyrka",
    code: "Botkyrka",
    longitude: 17.833333333333,
    latitude: 59.2,
  },
  {
    name: "Boxholm",
    code: "Boxholm",
    longitude: 15.051944444444,
    latitude: 58.196388888889,
  },
  {
    name: "Bromölla",
    code: "Bromölla",
    longitude: 14.466666666667,
    latitude: 56.05,
  },
  {
    name: "Bräcke",
    code: "Bräcke",
    longitude: 15.416666666667,
    latitude: 62.75,
  },
  {
    name: "Burlöv",
    code: "Burlöv",
    longitude: 13.066666666667,
    latitude: 55.633333333333,
  },
  {
    name: "Båstad",
    code: "Båstad",
    longitude: 12.866666666667,
    latitude: 56.416666666667,
  },
  {
    name: "Dals-Ed",
    code: "Dals-Ed",
    longitude: 11.916666666667,
    latitude: 58.916666666667,
  },
  {
    name: "Danderyd",
    code: "Danderyd",
    longitude: 18.083333333333,
    latitude: 59.4,
  },
  {
    name: "Degerfors",
    code: "Degerfors",
    longitude: 14.433333333333,
    latitude: 59.233333333333,
  },
  {
    name: "Dorotea",
    code: "Dorotea",
    longitude: 16.416666666667,
    latitude: 64.266666666667,
  },
  {
    name: "Eda",
    code: "Eda",
    longitude: 12.283333333333,
    latitude: 59.883333333333,
  },
  {
    name: "Ekerö",
    code: "Ekerö",
    longitude: 17.683333333333,
    latitude: 59.35,
  },
  {
    name: "Eksjö",
    code: "Eksjö",
    longitude: 14.966666666667,
    latitude: 57.666666666667,
  },
  {
    name: "Emmaboda",
    code: "Emmaboda",
    longitude: 15.55,
    latitude: 56.616666666667,
  },
  {
    name: "Enköping",
    code: "Enköping",
    longitude: 17.076388888889,
    latitude: 59.635555555556,
  },
  {
    name: "Eskilstuna",
    code: "Eskilstuna",
    longitude: 16.512777777778,
    latitude: 59.370555555556,
  },
  {
    name: "Eslöv",
    code: "Eslöv",
    longitude: 13.3,
    latitude: 55.833333333333,
  },
  {
    name: "Essunga",
    code: "Essunga",
    longitude: 12.716666666667,
    latitude: 58.183333333333,
  },
  {
    name: "Fagersta",
    code: "Fagersta",
    longitude: 15.816666666667,
    latitude: 60,
  },
  {
    name: "Falkenberg",
    code: "Falkenberg",
    longitude: 12.5,
    latitude: 56.916666666667,
  },
  {
    name: "Falköping",
    code: "Falköping",
    longitude: 13.55,
    latitude: 58.166666666667,
  },
  {
    name: "Falun",
    code: "Falun",
    longitude: 15.633333333333,
    latitude: 60.6,
  },
  {
    name: "Filipstad",
    code: "Filipstad",
    longitude: 14.166666666667,
    latitude: 59.716666666667,
  },
  {
    name: "Finspång",
    code: "Finspång",
    longitude: 15.786944444444,
    latitude: 58.709166666667,
  },
  {
    name: "Flen",
    code: "Flen",
    longitude: 16.588888888889,
    latitude: 59.058333333333,
  },
  {
    name: "Forshaga",
    code: "Forshaga",
    longitude: 13.466666666667,
    latitude: 59.533333333333,
  },
  {
    name: "Färgelanda",
    code: "Färgelanda",
    longitude: 11.983333333333,
    latitude: 58.566666666667,
  },
  {
    name: "Gagnef",
    code: "Gagnef",
    longitude: 15.133333333333,
    latitude: 60.55,
  },
  {
    name: "Gislaved",
    code: "Gislaved",
    longitude: 13.533333333333,
    latitude: 57.3,
  },
  {
    name: "Ljusdal",
    code: "Ljusdal",
    longitude: 16.0833333333333,
    latitude: 61.8333333333333,
  },
  {
    name: "Nordanstig",
    code: "Nordanstig",
    longitude: 17.0666666666667,
    latitude: 61.9833333333333,
  },
  {
    name: "Nordmaling",
    code: "Nordmaling",
    longitude: 19.5,
    latitude: 63.5666666666667,
  },
  {
    name: "Pajala",
    code: "Pajala",
    longitude: 23.3666666666667,
    latitude: 67.1833333333333,
  },
  {
    name: "Robertsfors",
    code: "Robertsfors",
    longitude: 20.85,
    latitude: 64.2,
  },
  {
    name: "Sollefteå",
    code: "Sollefteå",
    longitude: 17.2666666666667,
    latitude: 63.1666666666667,
  },
  {
    name: "Sorsele",
    code: "Sorsele",
    longitude: 17.5333333333333,
    latitude: 65.5333333333333,
  },
  {
    name: "Strömsund",
    code: "Strömsund",
    longitude: 15.5833333333333,
    latitude: 63.85,
  },
  {
    name: "Torsby",
    code: "Torsby",
    longitude: 13,
    latitude: 60.1333333333333,
  },
  {
    name: "Vansbro",
    code: "Vansbro",
    longitude: 14.2236111111111,
    latitude: 60.5111111111111,
  },
  {
    name: "Vilhelmina",
    code: "Vilhelmina",
    longitude: 16.65,
    latitude: 64.6166666666667,
  },
  {
    name: "Vindeln",
    code: "Vindeln",
    longitude: 19.7166666666667,
    latitude: 64.2,
  },
  {
    name: "Ånge",
    code: "Ånge",
    longitude: 15.6166666666667,
    latitude: 62.5166666666667,
  },
  {
    name: "Årjäng",
    code: "Årjäng",
    longitude: 12.1333333333333,
    latitude: 59.3833333333333,
  },
  {
    name: "Åsele",
    code: "Åsele",
    longitude: 17.35,
    latitude: 64.15,
  },
  {
    name: "Överkalix",
    code: "Överkalix",
    longitude: 22.8833333333333,
    latitude: 66.35,
  },
  {
    name: "Övertorneå",
    code: "Övertorneå",
    longitude: 23.6666666666667,
    latitude: 66.3833333333333,
  },
  {
    name: "Gnesta",
    code: "Gnesta",
    longitude: 17.312222222222,
    latitude: 59.0475,
  },
  {
    name: "Gnosjö",
    code: "Gnosjö",
    longitude: 13.733333333333,
    latitude: 57.366666666667,
  },
  {
    name: "Grums",
    code: "Grums",
    longitude: 13.1,
    latitude: 59.35,
  },
  {
    name: "Grästorp",
    code: "Grästorp",
    longitude: 12.666666666667,
    latitude: 58.333333333333,
  },
  {
    name: "Gullspång",
    code: "Gullspång",
    longitude: 14.116666666667,
    latitude: 58.983333333333,
  },
  {
    name: "Gällivare",
    code: "Gällivare",
    longitude: 20.65,
    latitude: 67.133333333333,
  },
  {
    name: "Gävle",
    code: "Gävle",
    longitude: 17.166666666667,
    latitude: 60.666666666667,
  },
  {
    name: "Göteborg",
    code: "Göteborg",
    longitude: 11.933333333333,
    latitude: 57.7,
  },
  {
    name: "Götene",
    code: "Götene",
    longitude: 13.483333333333,
    latitude: 58.533333333333,
  },
  {
    name: "Habo",
    code: "Habo",
    longitude: 14.066666666667,
    latitude: 57.916666666667,
  },
  {
    name: "Hagfors",
    code: "Hagfors",
    longitude: 13.65,
    latitude: 60.033333333333,
  },
  {
    name: "Hallsberg",
    code: "Hallsberg",
    longitude: 15.116666666667,
    latitude: 59.066666666667,
  },
  {
    name: "Hallstahammar",
    code: "Hallstahammar",
    longitude: 16.25,
    latitude: 59.616666666667,
  },
  {
    name: "Halmstad",
    code: "Halmstad",
    longitude: 12.85,
    latitude: 56.666666666667,
  },
  {
    name: "Hammarö",
    code: "Hammarö",
    longitude: 13.433333333333,
    latitude: 59.333333333333,
  },
  {
    name: "Haninge",
    code: "Haninge",
    longitude: 18.133333333333,
    latitude: 59.166666666667,
  },
  {
    name: "Haparanda",
    code: "Haparanda",
    longitude: 24.133333333333,
    latitude: 65.833333333333,
  },
  {
    name: "Heby",
    code: "Heby",
    longitude: 16.883333333333,
    latitude: 59.933333333333,
  },
  {
    name: "Hedemora",
    code: "Hedemora",
    longitude: 15.983333333333,
    latitude: 60.283333333333,
  },
  {
    name: "Helsingborg",
    code: "Helsingborg",
    longitude: 12.716666666667,
    latitude: 56.05,
  },
  {
    name: "Herrljunga",
    code: "Herrljunga",
    longitude: 13.033333333333,
    latitude: 58.083333333333,
  },
  {
    name: "Hjo",
    code: "Hjo",
    longitude: 14.283333333333,
    latitude: 58.3,
  },
  {
    name: "Hofors",
    code: "Hofors",
    longitude: 16.283333333333,
    latitude: 60.55,
  },
  {
    name: "Huddinge",
    code: "Huddinge",
    longitude: 17.983333333333,
    latitude: 59.233333333333,
  },
  {
    name: "Hudiksvall",
    code: "Hudiksvall",
    longitude: 17.116666666667,
    latitude: 61.733333333333,
  },
  {
    name: "Hultsfred",
    code: "Hultsfred",
    longitude: 15.833333333333,
    latitude: 57.483333333333,
  },
  {
    name: "Hylte",
    code: "Hylte",
    longitude: 13.25,
    latitude: 57,
  },
  {
    name: "Hällefors",
    code: "Hällefors",
    longitude: 14.516666666667,
    latitude: 59.766666666667,
  },
  {
    name: "Härjedalen",
    code: "Härjedalen",
    longitude: 14.35,
    latitude: 62.033333333333,
  },
  {
    name: "Härnösand",
    code: "Härnösand",
    longitude: 17.933333333333,
    latitude: 62.633333333333,
  },
  {
    name: "Härryda",
    code: "Härryda",
    longitude: 12.116666666667,
    latitude: 57.666666666667,
  },
  {
    name: "Hässleholm",
    code: "Hässleholm",
    longitude: 13.766666666667,
    latitude: 56.166666666667,
  },
  {
    name: "Håbo",
    code: "Håbo",
    longitude: 17.529722222222,
    latitude: 59.567777777778,
  },
  {
    name: "Höganäs",
    code: "Höganäs",
    longitude: 12.566666666667,
    latitude: 56.2,
  },
  {
    name: "Högsby",
    code: "Högsby",
    longitude: 16.033333333333,
    latitude: 57.166666666667,
  },
  {
    name: "Hörby",
    code: "Hörby",
    longitude: 13.65,
    latitude: 55.85,
  },
  {
    name: "Höör",
    code: "Höör",
    longitude: 13.55,
    latitude: 55.933333333333,
  },
  {
    name: "Jokkmokk",
    code: "Jokkmokk",
    longitude: 19.833333333333,
    latitude: 66.616666666667,
  },
  {
    name: "Järfälla",
    code: "Järfälla",
    longitude: 17.833333333333,
    latitude: 59.433333333333,
  },
  {
    name: "Jönköping",
    code: "Jönköping",
    longitude: 14.2,
    latitude: 57.783333333333,
  },
  {
    name: "Kalix",
    code: "Kalix",
    longitude: 23.166666666667,
    latitude: 65.85,
  },
  {
    name: "Kalmar",
    code: "Kalmar",
    longitude: 16.366666666667,
    latitude: 56.666666666667,
  },
  {
    name: "Karlsborg",
    code: "Karlsborg",
    longitude: 14.516666666667,
    latitude: 58.533333333333,
  },
  {
    name: "Karlshamn",
    code: "Karlshamn",
    longitude: 14.85,
    latitude: 56.166666666667,
  },
  {
    name: "Karlskoga",
    code: "Karlskoga",
    longitude: 14.525,
    latitude: 59.325,
  },
  {
    name: "Karlskrona",
    code: "Karlskrona",
    longitude: 15.65,
    latitude: 56.183333333333,
  },
  {
    name: "Karlstad",
    code: "Karlstad",
    longitude: 13.533333333333,
    latitude: 59.383333333333,
  },
  {
    name: "Katrineholm",
    code: "Katrineholm",
    longitude: 16.206111111111,
    latitude: 58.995555555556,
  },
  {
    name: "Kil",
    code: "Kil",
    longitude: 13.316388888889,
    latitude: 59.5,
  },
  {
    name: "Kinda",
    code: "Kinda",
    longitude: 15.627222222222,
    latitude: 57.989166666667,
  },
  {
    name: "Kiruna",
    code: "Kiruna",
    longitude: 20.222778,
    latitude: 67.854722,
  },
  {
    name: "Klippan",
    code: "Klippan",
    longitude: 13.133333333333,
    latitude: 56.133333333333,
  },
  {
    name: "Knivsta",
    code: "Knivsta",
    longitude: 17.8,
    latitude: 59.716666666667,
  },
  {
    name: "Kramfors",
    code: "Kramfors",
    longitude: 17.777166666667,
    latitude: 62.932166666667,
  },
  {
    name: "Kristianstad",
    code: "Kristianstad",
    longitude: 14.15,
    latitude: 56.033333333333,
  },
  {
    name: "Kristinehamn",
    code: "Kristinehamn",
    longitude: 14.116666666667,
    latitude: 59.3,
  },
  {
    name: "Krokom",
    code: "Krokom",
    longitude: 14.5,
    latitude: 63.316666666667,
  },
  {
    name: "Kumla",
    code: "Kumla",
    longitude: 15.133333333333,
    latitude: 59.116666666667,
  },
  {
    name: "Kungsbacka",
    code: "Kungsbacka",
    longitude: 12.066666666667,
    latitude: 57.483333333333,
  },
  {
    name: "Kungsör",
    code: "Kungsör",
    longitude: 16.083333333333,
    latitude: 59.416666666667,
  },
  {
    name: "Kungälv",
    code: "Kungälv",
    longitude: 11.966666666667,
    latitude: 57.866666666667,
  },
  {
    name: "Kävlinge",
    code: "Kävlinge",
    longitude: 13.116666666667,
    latitude: 55.8,
  },
  {
    name: "Köping",
    code: "Köping",
    longitude: 15.998611111111,
    latitude: 59.513055555556,
  },
  {
    name: "Laholm",
    code: "Laholm",
    longitude: 13.033333333333,
    latitude: 56.516666666667,
  },
  {
    name: "Landskrona",
    code: "Landskrona",
    longitude: 12.85,
    latitude: 55.866666666667,
  },
  {
    name: "Laxå",
    code: "Laxå",
    longitude: 14.6175,
    latitude: 58.988611111111,
  },
  {
    name: "Lekeberg",
    code: "Lekeberg",
    longitude: 14.866666666667,
    latitude: 59.166666666667,
  },
  {
    name: "Leksand",
    code: "Leksand",
    longitude: 15,
    latitude: 60.733333333333,
  },
  {
    name: "Lerum",
    code: "Lerum",
    longitude: 12.3,
    latitude: 57.766666666667,
  },
  {
    name: "Lessebo",
    code: "Lessebo",
    longitude: 15.266666666667,
    latitude: 56.75,
  },
  {
    name: "Lidingö",
    code: "Lidingö",
    longitude: 18.183333333333,
    latitude: 59.366666666667,
  },
  {
    name: "Lidköping",
    code: "Lidköping",
    longitude: 13.183333333333,
    latitude: 58.5,
  },
  {
    name: "Lilla Edet",
    code: "Lilla Edet",
    longitude: 12.133333333333,
    latitude: 58.133333333333,
  },
  {
    name: "Lindesberg",
    code: "Lindesberg",
    longitude: 15.25,
    latitude: 59.583333333333,
  },
  {
    name: "Linköping",
    code: "Linköping",
    longitude: 15.616666666667,
    latitude: 58.4,
  },
  {
    name: "Ljungby",
    code: "Ljungby",
    longitude: 13.933333333333,
    latitude: 56.833333333333,
  },
  {
    name: "Ljusnarsberg",
    code: "Ljusnarsberg",
    longitude: 14.983333333333,
    latitude: 59.866666666667,
  },
  {
    name: "Lomma",
    code: "Lomma",
    longitude: 13.083333333333,
    latitude: 55.666666666667,
  },
  {
    name: "Ludvika",
    code: "Ludvika",
    longitude: 15.183333333333,
    latitude: 60.133333333333,
  },
  {
    name: "Luleå",
    code: "Luleå",
    longitude: 22.153888888889,
    latitude: 65.584444444444,
  },
  {
    name: "Lund",
    code: "Lund",
    longitude: 13.2,
    latitude: 55.7,
  },
  {
    name: "Lycksele",
    code: "Lycksele",
    longitude: 18.666666666667,
    latitude: 64.6,
  },
  {
    name: "Lysekil",
    code: "Lysekil",
    longitude: 11.433333333333,
    latitude: 58.283333333333,
  },
  {
    name: "Malmö",
    code: "Malmö",
    longitude: 13.033333333333,
    latitude: 55.583333333333,
  },
  {
    name: "Malung-Sälen",
    code: "Malung-Sälen",
    longitude: 13.733333333333,
    latitude: 60.683333333333,
  },
  {
    name: "Malå",
    code: "Malå",
    longitude: 18.75,
    latitude: 65.183333333333,
  },
  {
    name: "Mariestad",
    code: "Mariestad",
    longitude: 13.816666666667,
    latitude: 58.7,
  },
  {
    name: "Markaryd",
    code: "Markaryd",
    longitude: 13.6,
    latitude: 56.466666666667,
  },
  {
    name: "Mark",
    code: "Mark",
    longitude: 12.683333333333,
    latitude: 57.516666666667,
  },
  {
    name: "Mellerud",
    code: "Mellerud",
    longitude: 12.466666666667,
    latitude: 58.7,
  },
  {
    name: "Mjölby",
    code: "Mjölby",
    longitude: 15.130555555556,
    latitude: 58.325,
  },
  {
    name: "Mora",
    code: "Mora",
    longitude: 14.533333333333,
    latitude: 61.016666666667,
  },
  {
    name: "Motala",
    code: "Motala",
    longitude: 15.0375,
    latitude: 58.536388888889,
  },
  {
    name: "Mullsjö",
    code: "Mullsjö",
    longitude: 13.883333333333,
    latitude: 57.916666666667,
  },
  {
    name: "Munkedal",
    code: "Munkedal",
    longitude: 11.683333333333,
    latitude: 58.483333333333,
  },
  {
    name: "Munkfors",
    code: "Munkfors",
    longitude: 13.533333333333,
    latitude: 59.833333333333,
  },
  {
    name: "Mölndal",
    code: "Mölndal",
    longitude: 12.019166666667,
    latitude: 57.654166666667,
  },
  {
    name: "Mönsterås",
    code: "Mönsterås",
    longitude: 16.45,
    latitude: 57.033333333333,
  },
  {
    name: "Mörbylånga",
    code: "Mörbylånga",
    longitude: 16.383333333333,
    latitude: 56.516666666667,
  },
  {
    name: "Nacka",
    code: "Nacka",
    longitude: 18.166666666667,
    latitude: 59.316666666667,
  },
  {
    name: "Nora",
    code: "Nora",
    longitude: 15.033333333333,
    latitude: 59.516666666667,
  },
  {
    name: "Norberg",
    code: "Norberg",
    longitude: 15.95,
    latitude: 60.083333333333,
  },
  {
    name: "Norrköping",
    code: "Norrköping",
    longitude: 16.185555555556,
    latitude: 58.591944444444,
  },
  {
    name: "Norrtälje",
    code: "Norrtälje",
    longitude: 18.7,
    latitude: 59.766666666667,
  },
  {
    name: "Norsjö",
    code: "Norsjö",
    longitude: 19.483333333333,
    latitude: 64.916666666667,
  },
  {
    name: "Nybro",
    code: "Nybro",
    longitude: 15.9,
    latitude: 56.733333333333,
  },
  {
    name: "Nykvarn",
    code: "Nykvarn",
    longitude: 17.4,
    latitude: 59.183333333333,
  },
  {
    name: "Nyköping",
    code: "Nyköping",
    longitude: 17.008611111111,
    latitude: 58.752777777778,
  },
  {
    name: "Nynäshamn",
    code: "Nynäshamn",
    longitude: 17.883333333333,
    latitude: 58.933333333333,
  },
  {
    name: "Nässjö",
    code: "Nässjö",
    longitude: 14.683333333333,
    latitude: 57.65,
  },
  {
    name: "Ockelbo",
    code: "Ockelbo",
    longitude: 16.716666666667,
    latitude: 60.883333333333,
  },
  {
    name: "Olofström",
    code: "Olofström",
    longitude: 14.533333333333,
    latitude: 56.266666666667,
  },
  {
    name: "Orsa",
    code: "Orsa",
    longitude: 14.616666666667,
    latitude: 61.116666666667,
  },
  {
    name: "Orust",
    code: "Orust",
    longitude: 11.683333333333,
    latitude: 58.233333333333,
  },
  {
    name: "Osby",
    code: "Osby",
    longitude: 13.983333333333,
    latitude: 56.366666666667,
  },
  {
    name: "Oskarshamn",
    code: "Oskarshamn",
    longitude: 16.45,
    latitude: 57.266666666667,
  },
  {
    name: "Ovanåker",
    code: "Ovanåker",
    longitude: 15.816666666667,
    latitude: 61.383333333333,
  },
  {
    name: "Oxelösund",
    code: "Oxelösund",
    longitude: 17.116666666667,
    latitude: 58.666666666667,
  },
  {
    name: "Partille",
    code: "Partille",
    longitude: 12.1,
    latitude: 57.733333333333,
  },
  {
    name: "Perstorp",
    code: "Perstorp",
    longitude: 13.383333333333,
    latitude: 56.133333333333,
  },
  {
    name: "Piteå",
    code: "Piteå",
    longitude: 21.5,
    latitude: 65.333333333333,
  },
  {
    name: "Ragunda",
    code: "Ragunda",
    longitude: 16.35,
    latitude: 63.1,
  },
  {
    name: "Gotland",
    code: "Gotland",
    longitude: 18.280555555556,
    latitude: 57.615277777778,
  },
  {
    name: "Ronneby",
    code: "Ronneby",
    longitude: 15.283333333333,
    latitude: 56.2,
  },
  {
    name: "Rättvik",
    code: "Rättvik",
    longitude: 15.133333333333,
    latitude: 60.883333333333,
  },
  {
    name: "Sala",
    code: "Sala",
    longitude: 16.606111111111,
    latitude: 59.920416666667,
  },
  {
    name: "Salem",
    code: "Salem",
    longitude: 17.683333333333,
    latitude: 59.233333333333,
  },
  {
    name: "Sandviken",
    code: "Sandviken",
    longitude: 16.783333333333,
    latitude: 60.616666666667,
  },
  {
    name: "Sigtuna",
    code: "Sigtuna",
    longitude: 17.883333333333,
    latitude: 59.633333333333,
  },
  {
    name: "Simrishamn",
    code: "Simrishamn",
    longitude: 14.35,
    latitude: 55.55,
  },
  {
    name: "Sjöbo",
    code: "Sjöbo",
    longitude: 13.7,
    latitude: 55.633333333333,
  },
  {
    name: "Skara",
    code: "Skara",
    longitude: 13.433333333333,
    latitude: 58.383333333333,
  },
  {
    name: "Skellefteå",
    code: "Skellefteå",
    longitude: 20.95,
    latitude: 64.75,
  },
  {
    name: "Skinnskatteberg",
    code: "Skinnskatteberg",
    longitude: 15.683333333333,
    latitude: 59.833333333333,
  },
  {
    name: "Skurup",
    code: "Skurup",
    longitude: 13.5,
    latitude: 55.483333333333,
  },
  {
    name: "Skövde",
    code: "Skövde",
    longitude: 13.85,
    latitude: 58.383333333333,
  },
  {
    name: "Smedjebacken",
    code: "Smedjebacken",
    longitude: 15.416666666667,
    latitude: 60.133333333333,
  },
  {
    name: "Sollentuna",
    code: "Sollentuna",
    longitude: 17.916666666667,
    latitude: 59.45,
  },
  {
    name: "Solna",
    code: "Solna",
    longitude: 18.016666666667,
    latitude: 59.366666666667,
  },
  {
    name: "Sotenäs",
    code: "Sotenäs",
    longitude: 11.25,
    latitude: 58.366666666667,
  },
  {
    name: "Staffanstorp",
    code: "Staffanstorp",
    longitude: 13.2,
    latitude: 55.633333333333,
  },
  {
    name: "Stenungsund",
    code: "Stenungsund",
    longitude: 11.816666666667,
    latitude: 58.083333333333,
  },
  {
    name: "Stockholm",
    code: "Stockholm",
    longitude: 18.066666666667,
    latitude: 59.35,
  },
  {
    name: "Storfors",
    code: "Storfors",
    longitude: 14.266666666667,
    latitude: 59.533333333333,
  },
  {
    name: "Storuman",
    code: "Storuman",
    longitude: 17.1,
    latitude: 65.1,
  },
  {
    name: "Strängnäs",
    code: "Strängnäs",
    longitude: 17.035277777778,
    latitude: 59.376944444444,
  },
  {
    name: "Strömstad",
    code: "Strömstad",
    longitude: 11.166666666667,
    latitude: 58.933333333333,
  },
  {
    name: "Sundbyberg",
    code: "Sundbyberg",
    longitude: 17.965555555556,
    latitude: 59.363333333333,
  },
  {
    name: "Sundsvall",
    code: "Sundsvall",
    longitude: 17.316666666667,
    latitude: 62.4,
  },
  {
    name: "Sunne",
    code: "Sunne",
    longitude: 13.133333333333,
    latitude: 59.833333333333,
  },
  {
    name: "Surahammar",
    code: "Surahammar",
    longitude: 16.216666666667,
    latitude: 59.716666666667,
  },
  {
    name: "Svalöv",
    code: "Svalöv",
    longitude: 13.116666666667,
    latitude: 55.916666666667,
  },
  {
    name: "Svedala",
    code: "Svedala",
    longitude: 13.233333333333,
    latitude: 55.5,
  },
  {
    name: "Svenljunga",
    code: "Svenljunga",
    longitude: 13.116666666667,
    latitude: 57.5,
  },
  {
    name: "Säffle",
    code: "Säffle",
    longitude: 12.933333333333,
    latitude: 59.133333333333,
  },
  {
    name: "Säter",
    code: "Säter",
    longitude: 15.75,
    latitude: 60.35,
  },
  {
    name: "Sävsjö",
    code: "Sävsjö",
    longitude: 14.666666666667,
    latitude: 57.4,
  },
  {
    name: "Söderhamn",
    code: "Söderhamn",
    longitude: 17.083333333333,
    latitude: 61.3,
  },
  {
    name: "Söderköping",
    code: "Söderköping",
    longitude: 16.3225,
    latitude: 58.480277777778,
  },
  {
    name: "Södertälje",
    code: "Södertälje",
    longitude: 17.516666666667,
    latitude: 59.1,
  },
  {
    name: "Sölvesborg",
    code: "Sölvesborg",
    longitude: 14.583333333333,
    latitude: 56.05,
  },
  {
    name: "Tanum",
    code: "Tanum",
    longitude: 11.316666666667,
    latitude: 58.716666666667,
  },
  {
    name: "Tibro",
    code: "Tibro",
    longitude: 14.166666666667,
    latitude: 58.416666666667,
  },
  {
    name: "Tidaholm",
    code: "Tidaholm",
    longitude: 13.95,
    latitude: 58.183333333333,
  },
  {
    name: "Tierp",
    code: "Tierp",
    longitude: 17.513055555556,
    latitude: 60.344444444444,
  },
  {
    name: "Timrå",
    code: "Timrå",
    longitude: 17.333333333333,
    latitude: 62.5,
  },
  {
    name: "Tingsryd",
    code: "Tingsryd",
    longitude: 14.983333333333,
    latitude: 56.533333333333,
  },
  {
    name: "Tjörn",
    code: "Tjörn",
    longitude: 11.55,
    latitude: 58,
  },
  {
    name: "Tomelilla",
    code: "Tomelilla",
    longitude: 13.95,
    latitude: 55.55,
  },
  {
    name: "Torsås",
    code: "Torsås",
    longitude: 16,
    latitude: 56.4,
  },
  {
    name: "Tranemo",
    code: "Tranemo",
    longitude: 13.35,
    latitude: 57.483333333333,
  },
  {
    name: "Tranås",
    code: "Tranås",
    longitude: 14.966666666667,
    latitude: 58.033333333333,
  },
  {
    name: "Trelleborg",
    code: "Trelleborg",
    longitude: 13.166666666667,
    latitude: 55.366666666667,
  },
  {
    name: "Trollhättan",
    code: "Trollhättan",
    longitude: 12.283333333333,
    latitude: 58.283333333333,
  },
  {
    name: "Trosa",
    code: "Trosa",
    longitude: 17.552222222222,
    latitude: 58.893888888889,
  },
  {
    name: "Tyresö",
    code: "Tyresö",
    longitude: 18.216666666667,
    latitude: 59.233333333333,
  },
  {
    name: "Täby",
    code: "Täby",
    longitude: 18.06872,
    latitude: 59.4439,
  },
  {
    name: "Töreboda",
    code: "Töreboda",
    longitude: 14.133333333333,
    latitude: 58.716666666667,
  },
  {
    name: "Uddevalla",
    code: "Uddevalla",
    longitude: 11.916666666667,
    latitude: 58.35,
  },
  {
    name: "Ulricehamn",
    code: "Ulricehamn",
    longitude: 13.412777777778,
    latitude: 57.791666666667,
  },
  {
    name: "Umeå",
    code: "Umeå",
    longitude: 20.25,
    latitude: 63.833333333333,
  },
  {
    name: "Upplands-Bro",
    code: "Upplands-Bro",
    longitude: 17.666666666667,
    latitude: 59.533333333333,
  },
  {
    name: "Upplands Väsby",
    code: "Upplands Väsby",
    longitude: 17.9,
    latitude: 59.516666666667,
  },
  {
    name: "Uppsala",
    code: "Uppsala",
    longitude: 17.633333333333,
    latitude: 59.866666666667,
  },
  {
    name: "Uppvidinge",
    code: "Uppvidinge",
    longitude: 15.333333333333,
    latitude: 57.166666666667,
  },
  {
    name: "Vadstena",
    code: "Vadstena",
    longitude: 14.897135,
    latitude: 58.445096111111,
  },
  {
    name: "Vaggeryd",
    code: "Vaggeryd",
    longitude: 14.116666666667,
    latitude: 57.5,
  },
  {
    name: "Valdemarsvik",
    code: "Valdemarsvik",
    longitude: 16.601944444444,
    latitude: 58.205555555556,
  },
  {
    name: "Vallentuna",
    code: "Vallentuna",
    longitude: 18.2,
    latitude: 59.583333333333,
  },
  {
    name: "Vara",
    code: "Vara",
    longitude: 12.95,
    latitude: 58.266666666667,
  },
  {
    name: "Varberg",
    code: "Varberg",
    longitude: 12.25,
    latitude: 57.1,
  },
  {
    name: "Vaxholm",
    code: "Vaxholm",
    longitude: 18.283333333333,
    latitude: 59.4,
  },
  {
    name: "Vellinge",
    code: "Vellinge",
    longitude: 13.016666666667,
    latitude: 55.466666666667,
  },
  {
    name: "Vetlanda",
    code: "Vetlanda",
    longitude: 15.066666666667,
    latitude: 57.433333333333,
  },
  {
    name: "Vimmerby",
    code: "Vimmerby",
    longitude: 15.85,
    latitude: 57.666666666667,
  },
  {
    name: "Vingåker",
    code: "Vingåker",
    longitude: 15.866666666667,
    latitude: 59.033333333333,
  },
  {
    name: "Vänersborg",
    code: "Vänersborg",
    longitude: 12.316666666667,
    latitude: 58.383333333333,
  },
  {
    name: "Vännäs",
    code: "Vännäs",
    longitude: 19.75,
    latitude: 63.916666666667,
  },
  {
    name: "Värmdö",
    code: "Värmdö",
    longitude: 18.566666666667,
    latitude: 59.316666666667,
  },
  {
    name: "Värnamo",
    code: "Värnamo",
    longitude: 14.033333333333,
    latitude: 57.183333333333,
  },
  {
    name: "Västervik",
    code: "Västervik",
    longitude: 16.633333333333,
    latitude: 57.75,
  },
  {
    name: "Västerås",
    code: "Västerås",
    longitude: 16.533333333333,
    latitude: 59.616666666667,
  },
  {
    name: "Växjö",
    code: "Växjö",
    longitude: 14.8,
    latitude: 56.883333333333,
  },
  {
    name: "Vårgårda",
    code: "Vårgårda",
    longitude: 12.8,
    latitude: 58.033333333333,
  },
  {
    name: "Ydre",
    code: "Ydre",
    longitude: 15.273888888889,
    latitude: 57.827222222222,
  },
  {
    name: "Ystad",
    code: "Ystad",
    longitude: 13.833333333333,
    latitude: 55.416666666667,
  },
  {
    name: "Älmhult",
    code: "Älmhult",
    longitude: 14.133333333333,
    latitude: 56.55,
  },
  {
    name: "Älvdalen",
    code: "Älvdalen",
    longitude: 14.033333333333,
    latitude: 61.233333333333,
  },
  {
    name: "Älvkarleby",
    code: "Älvkarleby",
    longitude: 17.416111111111,
    latitude: 60.624444444444,
  },
  {
    name: "Älvsbyn",
    code: "Älvsbyn",
    longitude: 21.016666666667,
    latitude: 65.683333333333,
  },
  {
    name: "Ängelholm",
    code: "Ängelholm",
    longitude: 12.85,
    latitude: 56.25,
  },
  {
    name: "Åmål",
    code: "Åmål",
    longitude: 12.7,
    latitude: 59.05,
  },
  {
    name: "Åre",
    code: "Åre",
    longitude: 13.466666666667,
    latitude: 63.35,
  },
  {
    name: "Åstorp",
    code: "Åstorp",
    longitude: 12.95,
    latitude: 56.133333333333,
  },
  {
    name: "Åtvidaberg",
    code: "Åtvidaberg",
    longitude: 15.998055555556,
    latitude: 58.201666666667,
  },
  {
    name: "Öckerö",
    code: "Öckerö",
    longitude: 11.647222222222,
    latitude: 57.711111111111,
  },
  {
    name: "Ödeshög",
    code: "Ödeshög",
    longitude: 14.652777777778,
    latitude: 58.230833333333,
  },
  {
    name: "Örebro",
    code: "Örebro",
    longitude: 15.213333333333,
    latitude: 59.273888888889,
  },
  {
    name: "Örkelljunga",
    code: "Örkelljunga",
    longitude: 13.283333333333,
    latitude: 56.283333333333,
  },
  {
    name: "Örnsköldsvik",
    code: "Örnsköldsvik",
    longitude: 18.733333333333,
    latitude: 63.283333333333,
  },
  {
    name: "Östersund",
    code: "Östersund",
    longitude: 14.666666666667,
    latitude: 63.183333333333,
  },
  {
    name: "Österåker",
    code: "Österåker",
    longitude: 18.45,
    latitude: 59.5,
  },
  {
    name: "Östhammar",
    code: "Östhammar",
    longitude: 18.373611111111,
    latitude: 60.258888888889,
  },
  {
    name: "Östra Göinge",
    code: "Östra Göinge",
    longitude: 14.066666666667,
    latitude: 56.25,
  },
];

export const countriesWithImage = countries.filter((c) =>
  countryCodesWithImage.includes(c.code)
);

export const smallCountryLimit = 5000;
export const bigEnoughCountriesWithImage = countriesWithImage; // note: removed "small country" filtering from here

export function getCountryName(language: string, country: Country) {
  return country.name;
}

export function sanitizeCountryName(countryName: string): string {
  return countryName
    .normalize("NFD")
    .replace(/[- '()]/g, "")
    .toLowerCase();
}
